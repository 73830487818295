@import "bootstrap";
@import "mixins";
@import "fonts";
@import "nav";

html {
	min-height: 100%;
}

.social-icon {
	width: 20px;
	height: 20px;
}
