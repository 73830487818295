@import "mixins";

@each $fontWeight in 100, 200, 300, 400, 500, 600, 700, 800, 900 {
	.fw-#{$fontWeight} {
		font-weight: $fontWeight;
	}
}

@each $fontSize in 90, 95, 100, 110, 120 {
	.fs-#{$fontSize} {
		font-size: percentage($fontSize * 0.01);
	}
}

@each $lineHeight in 1.0, 1.2, 1.5, 2.0 {
	.lh-#{$lineHeight * 10} {
		line-height: #{$lineHeight}em;
	}
}

@each $letterSpacing in 0.0125, 0.025, 0.05 {
	.ls-#{str_slice(quote($letterSpacing), 3)} {
		letter-spacing: #{$letterSpacing}em;
	}
}

%ff-open-sans {
	font-family: 'Open Sans', sans-serif;
}

%ff-roboto {
	font-family: 'Roboto', sans-serif;
}

body {
	@extend %ff-open-sans, .fw-300;
}

@include headings {
	@extend %ff-roboto, .fw-500, .ls-025, .text-dark;
}
